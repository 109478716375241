import react from "react";

import { Menu } from "../../../Components/Menu";
import { Rodape } from "../../../Components/Rodape";




export const Servicos = () => {
    return (
        <div className="bg-slate-600 bg-cover">
             <div className='mb-20'>
               <Menu /> 
               <br />
            </div>
            
            <div className="bg-slate-300 mt-5 mb-5 ml-10 mr-10 p-5 rounded-xl">
                <div className="grid grid-rows-2">
                    <p className="text-3xl text-center">Serviços Prestados</p>
                    <p className="">Uma equipe altamente treinada e preparada está ao seu dispor para realizar a regularização da sua atividade, de acordo com mais altos padrões de qualidade sanitária perante à legislação vigente.</p>
                </div>
            </div>

            <div className="bg-slate-200 mr-10 ml-10 rounded-xl">
                <div className="xl:grid xl:grid-cols-2 md:columns-1 md:p-5 sm:columns-1 sm:p-5 rounded-xl">

                    <div className="p-5">
                        <div className="flex flex-wrap justify-center">
                            <p className="border border-red-500 rounded-full p-14 text-5xl">CTA</p>
                        </div>
                        <p className=" hover:rotate-2 text-5xl pt-2 pb-3 text-center">Consultoria Técnica Administrativa</p>
                        <p className="border border-blue-900"></p>
                        <p className=" text-justify">
                        Atividade técnica que consiste na orientação necessária para ínicio de atividade empresarial, 
                        incluindo a adequação correta do objeto social e enquadramento frente a Classificação Nacional de Atividade Econômica (CNAE), 
                        regularização junto ao Conselho Regional de referência, e necessidade de documentação sanitária em nível local e federal, 
                        dimensionamento do quadro de recursos humanos, estimativa de investimento, suporte na contratação de serviços pertinentes a empresa.
                        </p>
                    </div>

                    <div className="p-5">
                        <div className="flex flex-wrap justify-center">
                            <p className="border border-red-500 rounded-full p-14 text-5xl">RD</p>
                        </div>
                        <p className=" hover:rotate-2 text-5xl pt-2 pb-3 text-center">Regularização Documental</p>
                        <p className="border border-blue-900"></p>
                        <p className=" text-justify">
                        Consiste na formação de processos para obtenção da licença sanitária em nível local, 
                        para obtenção de Autorização de Funcionamento Empresa (AFE) em nível federal, 
                        e para regularização das empresas junto ao conselho regional ao qual está ligado o responsável técnico.
                        </p>
                    </div>

                    <div className="p-5">
                        <div className="flex flex-wrap justify-center">
                            <p className="border border-red-500 rounded-full p-14 text-5xl">AS</p>
                        </div>
                        <p className=" hover:rotate-2 text-5xl pt-2 pb-3 text-center">Auditoria Sanitária</p>
                        <p className="border border-blue-900"></p>
                        <p className=" text-justify">
                        Atividade técnica que consiste na inspeção “in loco” do estabelecimento para identificação de não conformidades frente as exigências das normas de vigilância sanitária. 
                        Inclui também a análise documental e verificação de toda a documentação existente no serviço frente as diversas exigências.
                        </p>
                    </div>

                    <div className="p-5">
                        <div className="flex flex-wrap justify-center">
                            <p className="border border-red-500 rounded-full p-14 text-5xl">MBP</p>
                        </div>
                        <p className=" hover:rotate-2 text-5xl pt-2 pb-3 text-center">Manual de Boas Práticas</p>
                        <p className="border border-blue-900"></p>
                        <p className=" text-justify">
                        O Manual de Boas Práticas é um documento interno da empresa, mas que é auditado durante as inspeções sanitárias.
                        Deve abranger todas as atividades desenvolvidas na empresa, e se complementa com Procedimentos Operacionais Padronizados (POP) instruções de trabalhos planilhas de registro entre outros.
                        O INBRAVISA disponibiliza estes materiais o que reduz significativamente o tempo para sua total implantação, bastando que o responsável técnico da empresa faça as devidas adequações à sua realidade local.
                        </p>
                    </div>

                    <div className="p-5">
                        <div className="flex flex-wrap justify-center">
                            <p className="border border-red-500 rounded-full p-14 text-5xl">AC</p>
                        </div>
                        <p className=" hover:rotate-2 text-5xl pt-2 pb-3 text-center">Assessoria Continuada</p>
                        <p className="border border-blue-900"></p>
                        <p className=" text-justify">
                        Consiste em uma atividade constante do cliente INBRAVISA em todos os aspectos que envolvem a vigilância sanitária, seja de adequações, treinamentos, 
                        renovação de documentos entre outras, realizados de forma continuada, acompanhamento de processos e demais documentos junto aos órgãos federais, 
                        estaduais e municipais de vigilância sanitária com a emissão de relatório quinzenal.
                        </p>
                    </div>

                    <div className="p-5">
                        <div className="flex flex-wrap justify-center">
                            <p className="border border-red-500 rounded-full p-14 text-5xl">CNES</p>
                        </div>
                        <p className=" hover:rotate-2 text-5xl pt-2 pb-3 text-center">Cadastro Nacional de Estabelecimento de Saúde</p>
                        <p className="border border-blue-900"></p>
                        <p className=" text-justify">
                        Inclusão e digitalização de dados do estabelecimento junto ao Cadastro Nacional de Estabelecimento de Saúde (CNES) 
                        que resulta na emissão do Nº CNES solicitado pelas operadoras de convênio.
                        </p>
                    </div>


                    <div className="p-5">
                        <div className="flex flex-wrap justify-center">
                            <p className="border border-red-500 rounded-full p-14 text-5xl">PGRSS</p>
                        </div>
                        <p className=" hover:rotate-2 text-5xl pt-2 pb-3 text-center">Plano de Gerenciamento de Resíduos Sólidos de Serviço de Saúde</p>
                        <p className="border border-blue-900"></p>
                        <p className=" text-justify">
                        Atividades técnicas que consistem na elaboração de um plano destinado a racionalizar a geração, 
                        disposição e destino final dos resíduos sólidos de saúde.
                        </p>
                    </div>

                    <div className="p-5">
                        <div className="flex flex-wrap justify-center">
                            <p className="border border-red-500 rounded-full p-14 text-5xl">RDA</p>
                        </div>
                        <p className=" hover:rotate-2 text-5xl pt-2 pb-3 text-center">Recursos e Defesas Administrativas</p>
                        <p className="border border-blue-900"></p>
                        <p className=" text-justify">
                        Ações técnico jurídicas que consistem na interposição de recursos, 
                        defesas, pedido de desinterdição, reconsideração de despacho entre outros.
                        </p>
                    </div>
                    
                    



            </div>
            </div>

           



            <br /><br /><br /><br />



            <div class="relative mt-10">
                <Rodape />
            </div>
        </div>
    )
}