import react from "react";

import { Menu } from "../../../Components/Menu";
import { Rodape } from "../../../Components/Rodape";

var ano_atual = new Date().getFullYear();
var ano_informado = 2003;
console.log(ano_atual - ano_informado);



export const Empresa = () => {
    return (
        <div className="bg-slate-600 bg-cover text-xl">
             <div className='mb-20'>
               <Menu /> 
               <br />
            </div>
            <div className="bg-slate-300 mt-5 mb-5 ml-10 mr-10 p-5 rounded-xl">
                <div className="grid grid-rows-2">
                    <p className="text-3xl text-center">INBRAVISA  - Instituto Brasileiro de Auditoria em Vigilância Sanitária.</p>
                    <p className="text-justify">Entidade privada formada por especialistas em vigilância sanitária e que se destina,  a desenvolver atividades de consultoria, auditoria e mentorias para a adequação de produtos e serviços sob regime de vigilância sanitária, através de material técnico próprio, treinamentos, emitindo certificados de conformidade sanitária aos estabelecimentos que aderem aos seus programas de qualidade, que já promoveu a regularização de milhares de empresas e produtos ao longo de seus <strong> {ano_atual - ano_informado} anos de existência.</strong></p>

                </div>
            </div>
            <div className="bg-slate-200 mt-5 mb-10 ml-10 mr-10  rounded-xl">
                <div className="xl:columns-3 md:columns-1 md:p-5 sm:columns-1 sm:p-5">
                <div className="p-5">
                        <div className="flex justify-center">
                            <img className="hover:rotate-2" src="https://www.inbravisa.com.br/imagens/icone-serv.png" width="160" height="160" />
                        </div>
                        <p className=" hover:rotate-2 text-5xl pt-2 pb-3 text-center">Serviços</p>
                        <p className="border border-blue-900"></p>
                        <p className=" text-justify">Larga expertise na adequação e certificação de serviços médicos, odontológicos e laboratoriais, assim como serviços de alimentação, estética e embelezamento.</p>
                    </div>                 
                   
                    
                    <div className="p-5">
                        <div className="flex justify-center">
                            <img className="hover:rotate-2" src="https://www.inbravisa.com.br/imagens/icone-dist.png" width="160" height="160" />
                        </div>
                        <p className=" hover:rotate-2 text-5xl pt-2 pb-3 text-center">Distribuidora</p>
                        <p className="border border-blue-900"></p>
                        <p className=" text-justify">Nosso time está altamente capacitado para auxiliar na total adequação de empresas distribuidoras e importadoras de produtos de saúde, cosméticos, saneantes, medicamentos e alimentos, incluindo a certificação nas Boas Práticas de Armazenamento e Distribuição (BPAD).</p>

                    </div>

                    <div className="mb-32 p-5">
                        <div className="flex justify-center">
                            <img className=" hover:rotate-2" src="https://www.inbravisa.com.br/imagens/icone-industria.png" width="160" height="160" />
                        </div>
                        <p className=" hover:rotate-2 text-5xl pt-2 pb-3 text-center">Indústria</p>
                        <p className="border border-blue-900"></p>
                        <p className=" text-justify">Possuimos diversas soluções regulatórias para as indústrias que estão sob regime da vigilância sanitária, auxíliamos desde a abertura de uma nova indústria, passando pelo registro de produtos, além de uma manutenção do sistema de qualidade sanitário para a implantação de Boas Práticas de Fabricação (BPF).</p>

                    </div>
                    
                </div>

            </div>

            <div className="bg-slate-200 mt-5 mb-5 ml-10 mr-10 p-5 rounded-xl">
                <div className="grid grid-rows-2">
                    <p className="text-xl"></p>
                    <h2 class="featurette-heading"><u>Política de Qualidade</u></h2>
                    <p> <span class="text-muted">Missão, Visão e Valores</span></p>
            <h3> </h3>
              
              <p align="justify">A larga experiência da equipe técnica, reforçada pelo fato de seu diretor presidente ter ocupado vários cargos de direção junto a órgãos oficiais de vigilância sanitária no Estado de São Paulo, de 1996 à 2003, revela que a imensa maioria das irregularidades são cometidas por desconhecimento da legislação sanitária.</p>
              <p align="justify"><br />
                Desse modo, o INBRAVISA se apresenta como uma entidade especializada em vigilância sanitária para que as empresas do setor regulado sejam corretamente orientadas, aperfeiçoando seus processos produtivos e comerciais dentro dos mais modernos critérios sanitários de qualidade e segurança.</p>
              <p align="justify">Buscando sempre a excelência técnica dos serviços prestados, o INBRAVISA objetiva atender e superar as expectativas de seus clientes.</p>
            
            <p> <br/></p><h3><u>Missão Institucional</u></h3>            
              <p align="justify">
                <em>Atuar como referência técnica ao setor regulado, oferecendo consultorias e auditorias de excelente nível voltadas as reais necessidades de cada cliente através de material didático elaborado por profissionais especialistas, destinados a implantação, adequação, gerenciamento, manutenção, modificação e/ou criação de procedimentos exigidos pelas normas e leis sanitárias</em>.</p>
              <p align="justify"><em>Orienta com experiência dentro de um processo continuo de transferência de conhecimento, controles operacionais e avaliações, assegurando aos clientes de maneira ética e profissional a regularização de empresas e produtos dentro dos critérios legais.</em></p>
           
            <p> <br/></p>
            <h3><u>Visão</u></h3>
            
              <p align="justify">
                Ser entidade de referência nacional na prestação de serviços na área de vigilância sanitária para empresas do setor regulado e difundir os principios sanitários para a população em geral.</p>
            
            <p><br/> </p>
            <h3><u>Valores</u></h3>
            
              <p align="justify">
                - Trabalho com seriedade e compromisso<br />
                - Orientação de alto nível <br />
                - Conhecimento técnico e credibilidade<br />
                - Excelência na prestação de serviços<br />
                - Ética e responsabilidade</p>
           
          <p>
            
                
              

                    </p>

                </div>
            </div>



<br/><br/><br/><br/>



<div class="relative mt-10">
        <Rodape/>
        </div>
        </div>
    )
}