import React, { useState, useContext } from "react";
import { Context } from '../../Context/authContext'
import api from '../../config/configAPI';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { Menu } from "../../Components/Menu";
import { Rodape } from "../../Components/Rodape";


export const Login = () => {

    

    const { produto } = useParams();

    console.log('o Produto é:' + produto)


    const navigate = useNavigate();

    const { authenticated, signIn } = useContext(Context);

    console.log("Situação do Usuário na Página de Login: " + authenticated);

    const [user, setUser] = useState({
        Email: '',
        Senha: ''
    });

    const [status, setStatus] = useState({
        type: '',
        mensagem: '',
        loading: false
    });

    const valorinput = e => setUser({ ...user, [e.target.name]: e.target.value });

    const loginSubmit = async e => {
        e.preventDefault();
        setStatus({
            loading: true
        })
        const headers = {
            'Content-Type': 'application/json'
        }
        await api.post("/realizarlogin", user, { headers })
            .then((response) => {
                //console.log(response);
                setStatus({
                    type: 'Sucesso',
                    mensagem: response.data.mensagem,

                    loading: false
                });
                localStorage.setItem('token', response.data.token);
                localStorage.setItem('idUser', response.data.id);
                localStorage.setItem('Nome', response.data.Nome);
                localStorage.setItem('Id Asaas', response.data.IdAsaas)
                signIn(true);

                //console.log(response.data.token)

                { produto >= 1 ? navigate('/Checkout/' + produto) : navigate('/Area_Aluno/') }

            }).catch((err) => {
                if (err.response) {
                    //console.log("Erro tente mais tarde");
                    setStatus({
                        type: 'error',
                        mensagem: err.response.data.mensagem,
                        loading: false
                    });
                } else {
                    setStatus({
                        type: 'error',
                        mensagem: 'Erro, tente mais tarde!',
                        loading: false
                    });
                }
            });
    }



    return (

        <div>

            <h1>Login</h1>
            {status.type === 'error' ? <p>{status.mensagem}</p> : ''}
            {status.type === 'Sucesso' ? <p>{status.mensagem}</p> : ''}
            {status.loading ? <p>Verificando Login...</p> : ''}


        </div>,


        localStorage.getItem('token') ?
            <div>

                <div className="flex min-h-full flex-1 flex-col justify-center mb-10">
                <div className='mb-20'>
               <Menu /> 
               <br />
            </div>
                </div>

                <div className=" flex justify-center">
                    <p>Você já está logado, por favor acesse sua área do Aluno, <Link to="/Area_Aluno" className="text-sky-500">clique aqui</Link></p>

                </div>
            </div>
            :


            <div className="flex min-h-full flex-1 flex-col justify-center mb-10">
                <div className='mb-20'>
               <Menu /> 
            </div>
                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                    <img
                        className="mx-auto w-72 h-72"
                        src="http://www.inbravisa.com.br/imagens/logo%20INBRAVISA_FB%20(13).jpg"
                        alt="Logo Inbravisa"
                    />
                    <p className="mt-2 text-center text-sm text-gray-500 mb-5">
                        Não Possuí Cadastro?{' '}
                        <Link to={'/Cadastro_Usuario'} className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
                            Faça Agora Mesmo!
                        </Link>
                        <br />
                    </p>
                    {localStorage.getItem('Cadastrado') === '1' ?
                        <p className="bg-emerald-300 p-2 border rounded-xl text-center"> Cadastrado com Sucesso <br /> por favor faça o seu Login</p>

                        :
                        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                            {produto >= 1 ? <p>Para realizar a compra, é necessário estar logado</p> : <p>Por Favor Faça o Login</p>}
                        </h2>
                    }

                </div>

                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm p-5">
                    {status.type === 'error' ? <p className=" bg-red-800 text-white text-center">{status.mensagem}<br /></p> : ''}
                    {status.type === 'Sucesso' ? <p>{status.mensagem}</p> : ''}
                    {status.loading ? <p>Verificando Login...</p> : ''}
                    <form className="space-y-6" onSubmit={loginSubmit}>
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                Usuário
                            </label>
                            <div className="mt-2">
                                <input className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 p-2" type="text" name="Email" onChange={valorinput} placeholder="Digite o seu email" />
                            </div>
                        </div>
                        <div>
                            <div className="flex items-center justify-between">
                                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                    Senha
                                </label>
                                <div className="text-sm">
                                    <Link to={"/Rec_senha"} className="font-semibold text-indigo-600 hover:text-indigo-500">
                                        Esqueceu a senha?
                                    </Link>
                                </div>
                            </div>
                            <div className="mt-2">
                                <input className="p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" type="password" name="Senha" onChange={valorinput} autoComplete="on" placeholder="Digite a sua senha" />

                            </div>
                        </div>

                        <div>
                            <button
                                type="submit"
                                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                Realizar Login
                            </button>
                        </div>
                    </form>




                </div>


            </div>
    );
};