import react from "react";

import { Menu } from "../../../Components/Menu";
import { Rodape } from "../../../Components/Rodape";




export const Contato = () => {
    return (
        <div className="bg-slate-600 bg-cover text-xl">
            <div className='mb-20'>
                <Menu />
                <br />
            </div>

            <div className="bg-slate-300 mt-5 mb-5 ml-10 mr-10 p-5 rounded-xl">
                <div className="grid grid-rows-2">
                    <p className="text-3xl text-center">Estes são os nossos contatos</p>
                </div>
            </div>
            <div className="flex bg-slate-200 mr-10 ml-10 rounded-xl">
                <div className="xl:flex xl:flex-row md:columns-1 md:p-5 sm:columns-1 sm:p-5 rounded-xl">

                    <div className="p-5">
                        <div className="flex justify-center">
                            <img className="rounded-full hover:rotate-2" src="https://img.freepik.com/vetores-premium/logo-do-google-maps_578229-304.jpg" width="80" height="80" />
                        </div>
                        <p className=" hover:rotate-2 text-5xl pt-2 pb-3 text-center">Localização</p>
                        <p className="border border-blue-900"></p>
                        <p className=" text-justify">
                            Estamos Localizados na Av. Prof. Alfonso Bovero, 998 - Cj 16
                            Perdizes - São Paulo-SP - 05019-010
                        </p>
                    </div>
                    <div className="p-5">
                        <div className="flex justify-center">
                            <img className=" hover:rotate-2" src="https://cdn-icons-png.flaticon.com/512/126/126341.png" width="80" height="80" />
                        </div>
                        <p className=" hover:rotate-2 text-5xl pt-2 pb-3 text-center">Telefones e Email</p>
                        <p className="border border-blue-900"></p>
                        <p className=" text-justify">
                            Você pode entrar facilmente em contato conosco nos telefones:
                            <p> (11) 3672-7001</p>
                            <p>
                                <a href="mailto:inbravisa@inbravisa.com.br" className="text-blue-500 hover:underline">
                                    inbravisa@inbravisa.com.br
                                </a>
                            </p>
                        </p>
                    </div>
                    <div className="mb-32 p-5">
                        <div className="flex justify-center">
                            <img className="rounded-full hover:rotate-2" src="https://img.freepik.com/vetores-premium/conceito-de-icone-do-whatsapp_23-2147897840.jpg" width="80" height="80" />
                        </div>
                        <p className=" hover:rotate-2 text-5xl pt-2 pb-3 text-center">Whatsapp</p>
                        <p className="border border-blue-900"></p>
                        <br />

                        <a className="flex justify-center bg-emerald-500 text-white p-3 rounded-md" target="_blank" href="https://api.whatsapp.com/send?phone=551136727001">Clique aqui</a>


                    </div>

                </div>

            </div>
            <div className="bg-slate-300 mt-5 mb-5 ml-10 mr-10 p-5 rounded-xl">
                <div className="grid grid-rows-2">
                    <p className="text-xl text-center">Além disso, o INBRAVISA conta com uma gama Profissional nas mais diversas áreas para as suas melhores soluções</p>
                </div>
            </div>
            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
            <div class="relative mt-10">
                <Rodape />
            </div>
        </div>
    )
}