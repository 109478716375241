import react from "react";

import { Menu } from "../../../Components/Menu";
import { Rodape } from "../../../Components/Rodape";




export const Quemsomos = () => {
    return (
        <div className="bg-slate-600 bg-cover">
           <div className='mb-20'>
               <Menu /> 
               <br />
            </div>
            <div className="bg-slate-300 mt-5 mb-5 ml-10 mr-10 p-5 rounded-xl">
                <div className="grid grid-rows-2">
                    <p className="text-3xl text-center">Conheça Nossa Equipe Profissional</p>
                </div>
            </div>
            <div className="bg-slate-200 mr-10 ml-10 rounded-xl">
                <div className="xl:flex xl:flex-row md:columns-1 md:p-5 sm:columns-1 sm:p-5 rounded-xl">

                    <div className="p-5">
                        <div className="flex justify-center">
                            <img className="rounded-full hover:rotate-2" src="https://www.inbravisa.com.br/imagens/fotoRui.jpg" width="160" height="160" />
                        </div>
                        <p className=" hover:rotate-2 text-5xl pt-2 pb-3 text-center">Rui de Andrade Dammenhain</p>
                        <p className=" hover:rotate-2 pt-2 pb-3 text-center">Diretor Presidente</p>
                        <p className="border border-blue-900"></p>
                        <p className=" text-justify">
                            <ul><li> Especialista em Vigilância Sanitária;</li>
                                <li>Especialista em Saúde Pública;</li>
                                <li>Diretor do Grupo técnico Odontológico do Centro de Vigilância Sanitária (CVS) – 1996/1999;</li>
                                <li>Diretor Substituto da Divisão de Serviços de Saúde do Centro de Vigilância Sanitária (CVS) da Secretaria do Estado da Saúde – 1999 a 2001;</li>
                                <li>Diretor do Grupo Técnico de Vigilância Sanitária da Direção Regional de Saúde da Capital da Secretaria do Estado da Saúde - 2001 a 2003;</li>
                                <li>Professor responsável pela disciplina de normas sanitárias para diversos cursos de graduação e pós graduação na área da saúde.</li></ul>
                        </p>
                    </div>
                    <div className="p-5">
                        <div className="flex justify-center">
                            <img className=" hover:rotate-2" src="https://www.inbravisa.com.br/imagens/fotoValerianova.png" width="160" height="160" />
                        </div>
                        <p className=" hover:rotate-2 text-5xl pt-2 pb-3 text-center">Valeria Camargo Dammenhain</p>
                        <p className=" hover:rotate-2 pt-2 pb-3 text-center">Diretora Técnica</p>
                        <p className="border border-blue-900"></p>
                        <p className=" text-justify">
                            <ul>
                                <li>Especialista em Saúde Pública, com ênfase em Administração de Serviços de Saúde;</li>
                                <li>Avaliadora do Sistema ONA – Organização Nacional de Acreditação;</li>
                                <li>Assistente Técnica de Direção e Planejamento do Núcleo Regional de Saúde 1;</li>
                                <li>Diretora Técnica de diversas unidades de saúde da Secretaria do Estado da Saúde de São Paulo.</li>
                            </ul>
                        </p>
                    </div>
                    <div className="mb-32 p-5">
                        <div className="flex justify-center">
                            <img className="rounded-full hover:rotate-2" src="https://www.inbravisa.com.br/imagens/fotoVitor.jpeg" width="160" height="160" />
                        </div>
                        <p className=" hover:rotate-2 text-5xl pt-2 pb-3 text-center">Vitor Camargo Dammenhain</p>
                        <p className=" hover:rotate-2 pt-2 pb-3 text-center">Diretor Administrativo</p>
                        <p className="border border-blue-900"></p>
                        <p className=" text-justify">
                            <ul>
                                <li>Administrador de empresas - CRA-SP:122.954;</li>
                                <li>Gestão de micro e pequena empresa;</li>
                                <li>MBA em gestão dos serviços de saúde;</li>
                                <li>Analista e Desenvolvedor de Sistemas</li>
                            </ul>
                        </p>

                    </div>
                    
                </div>

            </div>
            <div className="bg-slate-300 mt-5 mb-5 ml-10 mr-10 p-5 rounded-xl">
                <div className="grid grid-rows-2">
                    <p className="text-xl text-center">Além disso, o INBRAVISA conta com uma gama Profissional nas mais diversas áreas para as suas melhores soluções</p>
                </div>
            </div>
            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
            <div class="relative mt-10">
        <Rodape/>
        </div>
        </div>
    )
}