import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Menus from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-router-dom';
import { FiMenu } from "react-icons/fi";
import logo from '../../Assets/logoSite.png'; // Atualize o caminho para o logotipo
import { TiThMenu } from "react-icons/ti";


export const Menu = () => {

  const token = localStorage.getItem('token');


  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const [isTransparent, setIsTransparent] = useState(true);


  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 0) {
        setIsTransparent(false);
      } else {
        setIsTransparent(true);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);



  return (
    <div>
    <nav className={`fixed top-0 mb-10 left-0 w-full flex items-center justify-between py-4 px-6 z-50 transition-all duration-300 ${isTransparent ? 'bg-gray-700 shadow-lg' : ' bg-gray-500 shadow-lg'}`}>
      <div className="flex flex-row items-center">
        <div>
          <button onClick={toggleMenu} className="pr-2 text-lg text-white"><TiThMenu/></button>
        </div>
        <div>
          <span onClick={toggleMenu} className='text-white cursor-pointer text-lg'> Menu</span>
        </div>
         
      </div>
      <div className="flex items-center text-white">
      <img src={logo} alt="INBRAVISA" className="h-14 mr-3 w-14 rounded-md " /> INBRAVISA
      </div>
      <div className="flex items-center">
        {
          !token ? 
            <Link to="/login"> 
              <Button color="primary" variant='contained'>Fazer Login</Button>
            </Link> 
          : 
            <Link to="/Area_Aluno">
              <button className='text-emerald-900 bg-yellow-400 hover:bg-yellow-500 p-2 rounded-lg border-white'>Área do Aluno</button>
            </Link>
        }
      </div>
    </nav>
    <div className={`fixed mt-20 inset-y-0 left-0 bg-gray-800 w-64 text-white transition-all duration-300 transform ${isOpen ? 'translate-x-0' : '-translate-x-full'}`}>
      <ul className="py-4">
        <Link to={'/'}><MenuItem onClick={handleClose}>Home</MenuItem></Link>
        <Link to={'/institucional/empresa'}><MenuItem onClick={handleClose}>Sobre a Empresa</MenuItem></Link>
        <Link to={'/institucional/servicos'}><MenuItem onClick={handleClose}>Serviços</MenuItem></Link>
        <Link to={'/institucional/quemsomos'}><MenuItem onClick={handleClose}>Equipe Profissional</MenuItem></Link>
        <Link to={'/institucional/contato'}><MenuItem onClick={handleClose}>Contato</MenuItem></Link>
      </ul>
    </div>
  </div>
  );
}

/* Menu Pronto 
<button className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded">Login</button>


 <div className=" bg-emerald-800 sm:p-2 p-5 flex justify-between text-white">
      <Button
        color="info"
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}   
        variant='contained'
      >
     <FiMenu/> 
      </Button>
      <Menus
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        
        <Link to={'/'}><MenuItem onClick={handleClose}>Home</MenuItem></Link>
        <Link to={'/institucional/empresa'}><MenuItem onClick={handleClose}>Sobre a Empresa</MenuItem></Link>
        <Link to={'/institucional/servicos'}><MenuItem onClick={handleClose}>Serviços</MenuItem></Link>
        <Link to={'/institucional/quemsomos'}><MenuItem onClick={handleClose}>Equipe Profissional</MenuItem></Link>
        <Link to={'/institucional/contato'}><MenuItem onClick={handleClose}>Contato</MenuItem></Link>
        
       
        
      </Menus>

      <div>
          <p className='ml-5 text-3xl'>INBRAVISA </p>
        </div>
        
        <div className='sm:p-2 p-5'>

      {
         !token  ? <Link to="/login"> <Button color="primary" variant='contained'>Fazer Login</Button></Link> :
         <Link to="/Area_Aluno"><button className=' text-emerald-900 bg-yellow-400 hover:bg-yellow-500 p-2 rounded-lg border-white'>Área do Aluno</button></Link>

        
      }
        
        </div>

    </div>
*/