import React from "react";




export const Rodape = () => {
    return (
        <div className=" bg-slate-800 pl-5 text-white absolute inset-x-0 bottom-0  ">
            <div className="text-center  ">
                <p>INBRAVISA - Instituto Brasileiro de Auditoria em Vigilância Sanitária - CNPJ: 05.832.414/0001-09 </p>
                <p>Av. Prof. Alfonso Bovero, 998 - Cj 16 - Perdizes - São Paulo-SP</p>
                <p> <a className="flex justify-center p-3 rounded-md text-blue-500 hover:underline" target="_blank" href="https://api.whatsapp.com/send?phone=551136727001"> Telefone e WhatsApp - (11) 3672-7001</a></p>
                <p><a href="mailto:inbravisa@inbravisa.com.br" className="text-blue-500 hover:underline">
                    inbravisa@inbravisa.com.br
                </a></p>

            </div>
            <br />            
        </div>
    )
}