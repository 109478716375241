import React, { useState } from 'react';
import { Card } from '../../Components/Card';
import { Menu } from '../../Components/Menu';
import { Jumbo } from '../../Components/Descricao';
import { Rodape } from "../../Components/Rodape";

import { Listar } from "../../Components/Listar";

import { CookieConsent } from '../../Components/CookieConsent';


function sair() {

    //console.log("Saiu")
    //localStorage.removeItem("idUser");
    //localStorage.removeItem("token");
    //localStorage.removeItem("Nome");



}



export const Home = () => {
    localStorage.removeItem('ValorCobrado');
    localStorage.removeItem('ValorCompra');
    localStorage.removeItem('StatusCobranca');
    localStorage.removeItem('TipoCobranca');
    localStorage.removeItem('IdCobranca');
    localStorage.removeItem('Img');
    localStorage.removeItem('idaula');
    localStorage.removeItem('NomeAula');


    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };


    return (

        <div>
            <div className='mb-20'>
                <Menu />
                <br />
            </div>


            <div className="bg-slate-300 p-5 flex ml-2 mr-2  mt-20 justify-center ">
                <Jumbo />

            </div>

            <div className="flex flex-wrap justify-center mb-64">
                <Card />

            </div>
            <div>
                <CookieConsent />
            </div>
            <div class="relative mt-10">
                <Rodape />

            </div>
        </div>
    )
}